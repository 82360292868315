import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useMemo, useReducer } from 'react';
// utils
import axios from '../utils/axios';
import localStorageAvailable from '../utils/localStorageAvailable';
import bootIntercom, { shutdownIntercom } from '../utils/intercom';
//
import { getDeviceId, setSession } from './utils';
import { api } from '../redux/services/api';
import { store } from '../redux/store';
import { NotificationActions } from '../redux/slice/notification';
import userRoles from './roles.json';
import businessPlans from './businessPlans';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isInitialized: false,
  isAuthenticated: false,
  isActiveBilling: false,
  user: null,
  business: null,
  businesses: [],
  businessRoles: [],
  role: '',
  billingChannel: null,
  userAPIFailure: null,
  allowStripeBilling: null,
  onboardingSteps: null,
  businessPlan: null,
  isMultiInventory: null,
  isMultiBusiness: false,
  hasOnlyShopifyIntegration: false,
  isDashboardV2Enabled: false,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    const {
      isAuthenticated,
      user,
      role,
      business,
      userAPIFailure,
      isActiveBilling,
      billingChannel,
      allowStripeBilling,
    } = action.payload;
    const businesses = [];

    const isShopifyIntegration = business?.shopify && !business?.clover;

    const isMultiInventoryAccount = !!(
      business?.is_multi_business ||
      (business?.clover?.merchant_id && business?.shopify?.shop)
    );

    if (isMultiInventoryAccount) {
      businesses.push({
        id: business.id || business._id,
        name: business.location_name || business.name,
      });
      businesses.push(
        ...(business.linked_businesses?.map((o) => ({ ...o, name: o.location_name || o.name })) ||
          [])
      );
    }

    const isMainBusiness =
      (business?.is_multi_business && business?.is_main_business) || !business?.main_business_id;

    const mainBusinessRole =
      !business?.is_multi_business || business?.is_main_business ? 'main_business' : undefined;

    const canManageBilling =
      !business?.is_multi_business || business?.is_main_business || !business?.is_shared_billing
        ? 'manage_billing'
        : false;

    const allowSms = business?.allow_sms ? 'allow_sms' : false;

    const canSendSms =
      business?.allow_sms &&
      (business?.sms_channel === 'twilio' ||
        business?.bandwidth?.toll_free_verification_status?.toLowerCase() === 'verified')
        ? 'can_send_sms'
        : false;

    let totalOnboardingStatus = 0;
    let pendingOnboardingStatus = 0;
    if (business?.onboarding_status) {
      Object.keys(business.onboarding_status).forEach((key) => {
        if (typeof business.onboarding_status[key] !== 'boolean') return;

        if (key === 'activate_toll_free_number' && !business?.allow_sms) return;

        totalOnboardingStatus += 1;

        if (!business.onboarding_status[key]) {
          pendingOnboardingStatus += 1;
        }
      });
    }

    const allowGettingStarted = pendingOnboardingStatus >= 1 ? 'allow_getting_started' : false;

    let businessRoles = [];
    const ownerAndAdminAccess = ['owner', 'admin'].includes(role);
    const ownerAccess = ['owner'].includes(role);
    const adminAccess = ['admin'].includes(role);

    if (mainBusinessRole && ownerAndAdminAccess) {
      businessRoles.push(mainBusinessRole);
    }

    if (canManageBilling && ownerAccess) {
      businessRoles.push(canManageBilling);
    }
    if (allowSms && mainBusinessRole) {
      businessRoles.push(allowSms);
    }
    if (canSendSms && mainBusinessRole) {
      businessRoles.push(canSendSms);
    }
    if (allowGettingStarted && mainBusinessRole) {
      businessRoles.push(allowGettingStarted);
      business.onboarding_status = {
        ...business.onboarding_status,
        totalOnboardingStatus,
        pendingOnboardingStatus,
      };
    }

    let roleAccess = role;

    if (adminAccess && isMainBusiness) {
      roleAccess = 'admin_main';
    }

    if (ownerAccess && !isMainBusiness && business?.is_multi_business) {
      roleAccess = 'owner_sub';
    }

    const user_roles = userRoles[roleAccess];
    const plan = business?.billing_plan ?? 'none';

    if (user_roles && user_roles?.length > 0 && businessPlans[plan]) {
      let permittedFeatures = user_roles.filter((userRole) =>
        businessPlans[plan].includes(userRole)
      );

      if (plan === 'premium' && !business?.is_cashback_available) {
        permittedFeatures = permittedFeatures.filter((o) => o !== 'cashback_program');
      }

      businessRoles.push(...permittedFeatures);
    }

    if (isMainBusiness && ['admin_main', 'manager', 'marketing'].includes(roleAccess)) {
      businessRoles.push('campaigns');
    }

    if (plan === 'basic' || plan === 'none') {
      const restrictedFeatures = [
        'can_send_sms',
        'allow_sms',
        'campaigns',
        'allow_getting_started',
      ];
      businessRoles = businessRoles.filter((o) => !restrictedFeatures.includes(o));
    }

    if (plan === 'none') {
      businessRoles.push(...['settings_personal', 'settings_business', 'settings_integrations']);
    }

    return {
      isInitialized: true,
      isAuthenticated,
      isActiveBilling,
      user,
      business,
      businesses,
      businessRoles,
      role,
      billingChannel,
      userAPIFailure,
      isLoading: false,
      allowStripeBilling,
      onboardingSteps: business?.onboarding_status ?? null,
      businessPlan: plan,
      isMultiInventory: isMultiInventoryAccount,
      isMultiBusiness: business?.is_multi_business || false,
      hasOnlyShopifyIntegration: isShopifyIntegration,
      isDashboardV2Enabled: business?.is_dashboard_v2_enabled ?? false,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      isAuthenticated: true,
      isActiveBilling: action.payload.isActiveBilling,
      user: action.payload.user,
      billingChannel: null,
      isLoading: false,
      businessPlan: null,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      isAuthenticated: true,
      isActiveBilling: action.payload.isActiveBilling,
      user: action.payload.user,
      isLoading: false,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isInitialized: true,
      isAuthenticated: false,
      isActiveBilling: false,
      user: null,
      business: null,
      businesses: [],
      businessRoles: [],
      billingChannel: null,
      userAPIFailure: null,
      isLoading: false,
      allowStripeBilling: null,
      onboardingSteps: null,
      businessPlan: null,
      isMultiInventory: null,
      isMultiBusiness: false,
      hasOnlyShopifyIntegration: false,
      isDashboardV2Enabled: false,
    };
  }
  if (action.type === 'LOADING') {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === 'RESET_INITIALIZATION_STATUS') {
    return {
      ...state,
      isInitialized: false,
    };
  }
  if (action.type === 'UPDATE_ONBOARDING_STATUS') {
    if (!state?.onboardingSteps || state?.onboardingSteps?.[action.payload.status]) return state;

    const { pendingOnboardingStatus } = state.onboardingSteps;

    const updatedOnboardingState = {
      ...state.onboardingSteps,
      pendingOnboardingStatus: pendingOnboardingStatus - 1,
      [action.payload.status]: true,
    };

    const { businessRoles } = state;
    let updatedBusinessRoles = null;

    if (updatedOnboardingState.pendingOnboardingStatus === 0) {
      updatedBusinessRoles = businessRoles.filter((role) => role !== 'allow_getting_started');
    }

    return {
      ...state,
      businessRoles: updatedBusinessRoles ?? businessRoles,
      onboardingSteps: updatedOnboardingState,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    try {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';

      if (accessToken) {
        setSession(accessToken);
        dispatch({ type: 'LOADING' });

        const response = await axios.get('/users');

        const { user, business, role, is_active_billing, billing_channel, allow_stripe_billing } =
          response.data;

        // Logged in user
        bootIntercom({
          name: `${user.first_name} ${user.last_name}`,
          email: user.email,
          user_id: user._id,
          created_at: user.created_at,
          phone: user.phone_number,
          company: {
            id: business._id,
            name: business.name ?? business.brand,
            industry: business.type,
          },
        });

        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: true,
            isActiveBilling: is_active_billing,
            user,
            role,
            business,
            billingChannel: billing_channel,
            allowStripeBilling: allow_stripe_billing,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: false,
            isActiveBilling: false,
            user: null,
            business: null,
            businesses: [],
            businessRoles: [],
            role: '',
            billingChannel: null,
            allowStripeBilling: null,
          },
        });
      }
    } catch (error) {
      if (error?.statusCode === 401) {
        store.dispatch(NotificationActions.handleUnauthorizeAccessAlert(error));
        store.dispatch({ type: 'UNAUTHORIZED_ACCESS', payload: undefined });
        return;
      }

      dispatch({
        type: 'INITIAL',
        payload: {
          isAuthenticated: false,
          isActiveBilling: false,
          user: null,
          business: null,
          businesses: [],
          businessRoles: [],
          role: '',
          billingChannel: null,
          allowStripeBilling: null,
          userAPIFailure: error,
        },
      });
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(
    async (email, password) => {
      const response = await axios.post('/auth/login', {
        email,
        password,
        device_id: getDeviceId(),
      });
      const { access_token } = response.data;

      setSession(access_token);

      initialize();
    },
    [initialize]
  );

  // REQUEST CODE
  const requestCode = useCallback(async (email) => {
    const response = await axios.post('/auth/request-code', {
      email,
      device_id: getDeviceId(),
    });
    return response.data;
  }, []);

  // RESET PASSWORD
  const resetPassword = useCallback(
    async (email, code, password) => {
      const response = await axios.post('/auth/reset-password', {
        email,
        code,
        password,
        device_id: getDeviceId(),
      });
      const { access_token } = response.data;

      setSession(access_token);

      initialize();
    },
    [initialize]
  );

  // LOGOUT
  const logout = useCallback(() => {
    setSession(null);
    dispatch(api.util.resetApiState());
    dispatch({
      type: 'LOGOUT',
    });
    store.dispatch({ type: 'LOGOUT', payload: undefined });
    localStorage.clear();
    sessionStorage.clear();
    // shutdown (clear previous instance)
    shutdownIntercom();
    // start new instance
    bootIntercom();
  }, []);

  // REGISTER
  const register = useCallback(
    async (body, headers) => {
      const response = await axios.post(
        '/businesses/register',
        {
          ...body,
          device_id: getDeviceId(),
        },
        {
          headers,
        }
      );

      const activeSession = localStorage.getItem('accessToken');
      if (activeSession) {
        await logout();
      }

      dispatch({ type: 'RESET_INITIALIZATION_STATUS' });

      const { access_token } = response.data;

      localStorage.setItem('accessToken', access_token);

      setSession(access_token);

      initialize();
    },
    [initialize, logout]
  );

  // CLOVER LOGIN
  const cloverLogin = useCallback(
    async (merchant_id, code) => {
      const response = await axios.post('/clover/login/web', {
        merchant_id,
        code,
        device_id: getDeviceId(),
      });

      const activeSession = localStorage.getItem('accessToken');

      if (activeSession) {
        await logout();
      }

      const { access_token } = response.data;

      setSession(access_token);

      dispatch({ type: 'RESET_INITIALIZATION_STATUS' });

      initialize();
    },
    [initialize, logout]
  );

  // SHOPIFY LOGIN
  const shopifyLogin = useCallback(
    async (shop, code) => {
      const response = await axios.post('/shopify/login/web', {
        shop,
        code,
        device_id: getDeviceId(),
      });

      const activeSession = localStorage.getItem('accessToken');

      if (activeSession) {
        await logout();
      }

      const { access_token } = response.data;

      setSession(access_token);

      dispatch({ type: 'RESET_INITIALIZATION_STATUS' });

      initialize();
    },
    [initialize, logout]
  );

  const reInitialization = useCallback(async () => {
    dispatch({ type: 'RESET_INITIALIZATION_STATUS' });
    initialize();
  }, [initialize]);

  const updateOnboardingStepStatus = useCallback((status) => {
    dispatch({ type: 'UPDATE_ONBOARDING_STATUS', payload: { status } });
  }, []);

  const setUserToken = useCallback(
    async (access_token) => {
      setSession(access_token);

      dispatch({ type: 'RESET_INITIALIZATION_STATUS' });

      reInitialization();
    },
    [reInitialization]
  );

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      isActiveBilling: state.isActiveBilling,
      user: state.user,
      business: state.business,
      businesses: state.businesses,
      businessRoles: state.businessRoles,
      role: state.role,
      billingChannel: state.billingChannel,
      userAPIFailure: state.userAPIFailure,
      isLoading: state.isLoading,
      allowStripeBilling: state.allowStripeBilling,
      onboardingSteps: state.onboardingSteps,
      businessPlan: state.businessPlan,
      isMultiInventory: state.isMultiInventory,
      isMultiBusiness: state.isMultiBusiness,
      hasOnlyShopifyIntegration: state.hasOnlyShopifyIntegration,
      isDashboardV2Enabled: state.isDashboardV2Enabled,
      method: 'jwt',
      initialize,
      login,
      requestCode,
      resetPassword,
      register,
      logout,
      cloverLogin,
      reInitialization,
      updateOnboardingStepStatus,
      setUserToken,
      shopifyLogin,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.isActiveBilling,
      state.user,
      state.business,
      state.businesses,
      state.businessRoles,
      state.role,
      state.billingChannel,
      state.userAPIFailure,
      state.isLoading,
      state.allowStripeBilling,
      state.onboardingSteps,
      state.businessPlan,
      state.isMultiInventory,
      state.isMultiBusiness,
      state.hasOnlyShopifyIntegration,
      state.isDashboardV2Enabled,
      initialize,
      login,
      requestCode,
      resetPassword,
      logout,
      register,
      cloverLogin,
      reInitialization,
      updateOnboardingStepStatus,
      setUserToken,
      shopifyLogin,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
